import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { sessionServerBaseUrl, sessionServerNoHttp, userInfoServerBaseUrl } from "./env";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Oval, ThreeDots } from "react-loader-spinner";
import { Box, Button, Paper, Typography } from "@mui/material";
import NavBar from "./components/NavBar";
import { RootState } from "./redux/store";
import { authSliceType } from "./redux/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faInfoCircle, faKiwiBird } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import SessionCard from "./components/SessionCard/SessionCard";
import ResultModal from "./components/ClipPage/ResultModal";
import ThumbPlayer from "./components/ListSessionsPage/ ThumbPlayer";
import ImageOrInfoModal from "./components/ImageOrInfoModal";
import ResultInfo from "./components/ResultInfo";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { io } from "socket.io-client";

const now = new Date().toISOString();

const ListSessionsPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const [loading, setLoading] = useState(true);
  type currSessionType = {
    time: Date;
    error: boolean | null;
    quality: string;
    title?: string | null;
    srcImg?: string | null;
    resultUrls?: string[] | null;
    preview?: string | null;
  };
  type pastSessionType = {
    time: Date;
    privateUrls: string[];
    quality: string;
    title?: string | null;
    srcImg?: string | null;
    preview: string;
  };
  const [currSessions, setCurrSessions] = useState<Map<string, currSessionType>>(null);
  const [pastSessions, setPastSessions] = useState<Map<string, pastSessionType>>(null);
  const [allSessionsLoaded, setAllSessionsLoaded] = useState(false);
  const [showError, setShowError] = useState<string | null>(null);
  const [infoModalActive, setInfoModalActive] = useState<number | undefined>(undefined);

  const [socketOpened, setSocketOpened] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  // Combine currSessions and pastSessions for display
  const combinedSessions = useMemo(
    () =>
      [
        ...Array.from(currSessions?.entries() || []),
        ...Array.from(pastSessions?.entries() || []),
      ].sort((a, b) => new Date(b[1].time).getTime() - new Date(a[1].time).getTime()),
    [currSessions, pastSessions]
  );

  // Get sessions to display based on current page
  const sessionsToDisplay = useMemo(
    () => combinedSessions.slice(0, currentPage * itemsPerPage),
    [combinedSessions, currentPage]
  );

  const activeSession =
    infoModalActive === undefined
      ? null
      : (sessionsToDisplay.at(infoModalActive) as any)?.at(1);

  // Type guard to check if session is pastSessionType
  const isPastSession = (session: currSessionType | pastSessionType) => {
    return (
      (session as pastSessionType).privateUrls !== undefined ||
      (session as currSessionType).resultUrls !== undefined
    );
  };

  const getSessions = async () => {
    let response = null;
    try {
      response = await fetch(`${userInfoServerBaseUrl}/listSessions`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          auth,
          onlyCurr: false,
          currSessionsSize: currSessions?.size || 0,
        }),
      });
    } catch (error) {
      setLoading(false);
      setShowError("Couldn't fetch from infoandsesh server");
      console.error("Fetch error: ", error);
      return;
    }

    if (!response) {
      setLoading(false);
      setShowError("Response not received");
      console.log("Error: response not received");
      return;
    }

    let resObj = await response.json();

    if (resObj.error) {
      setShowError(resObj.error);
      setLoading(false);
      console.log(`Response was an error :: ${resObj.error}`);
      return;
    } else if (resObj) {
      setCurrSessions(new Map(resObj.currSessions));
      if (resObj.pastSessions) {
        setPastSessions(new Map(resObj.pastSessions));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (auth?.jwt) {
      getSessions();
    }
  }, [auth]);

  const socketRef = useRef(null);

  useEffect(() => {
    let mustUpdate = false;
    if (currSessions) {
      for (const [key, sess] of currSessions) {
        if (!sess.error) {
          mustUpdate = true;
          break;
        }
      }
    }
    if (mustUpdate && !socketOpened) {
      console.log("opening socket...");
      socketRef.current = io(`wss://${sessionServerNoHttp}`, {
        query: {
          subId: auth.subId,
          token: auth.jwt,
        },
      });
      console.log(socketRef.current);
      setSocketOpened(true);

      socketRef.current?.on("connect_error", (error) => {
        console.error("Socket.IO connection error: ", error);
        return socketRef.current?.disconnect();
      });

      socketRef.current?.on("sessionUpdate", (data) => {
        console.log("Received session update:", data);
        console.log("Currsessions:", currSessions);
        if (currSessions) {
          setCurrSessions((prevSessions) => {
            const newCurr = new Map(prevSessions);
            if (newCurr.has(data.seshId)) {
              console.log(`Updating session: ${data.seshId}`);
              newCurr.set(data.seshId, data);
              return newCurr;
            }
            return prevSessions;
          });
        }
      });

      socketRef.current?.on("disconnect", (reason) => {
        // Handle disconnections
        console.log("Socket.IO disconnected:", reason);
      });
    }
  }, [currSessions, auth, socketOpened]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        console.log("Closing WebSocket connection");
        socketRef.current.disconnect();
        setSocketOpened(false);
      }
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading
    ) {
      return;
    }
    if (sessionsToDisplay.length < combinedSessions.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      setAllSessionsLoaded(true);
    }
  }, [combinedSessions.length, sessionsToDisplay.length, loading]);

  const handleClickInfo = (index: number) => {
    setInfoModalActive(index);
  };

  const handleCloseInfo = () => {
    setInfoModalActive(undefined);
  };

  const formatDate = (dateString: string) => {
    const parsedDate = parseISO(dateString);
    const zonedDate = toZonedTime(parsedDate, "America/New_York");
    return format(zonedDate, "EEE MMM dd yyyy hh:mm:ssaa");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, sessionsToDisplay?.length, combinedSessions?.length, handleScroll]);

  if (!auth?.jwt) {
    return (
      <div className="flex flex-col items-center content-center w-full">
        <NavBar signinInProgressRef={null} />
        Sign in to view this page.
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center content-center">
        <NavBar signinInProgressRef={null} />
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#6686af"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <Box>
      <NavBar signinInProgressRef={null} />
      <ImageOrInfoModal
        modalActive={infoModalActive !== undefined}
        handleClose={handleCloseInfo}
        component={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ResultInfo
              src={activeSession?.srcImg}
              dst={activeSession?.url}
              dstIsVideo={true}
            />
            {sessionsToDisplay.at(infoModalActive) && (
              <Paper
                sx={{
                  mt: 2,
                  p: 2,
                  display: "flex",
                  width: "fit-content",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Started:
                </Typography>
                <Typography variant="body1">
                  {formatDate(
                    activeSession?.time ? new Date(activeSession.time).toISOString() : now
                  )}
                </Typography>
                {isPastSession(sessionsToDisplay?.at(infoModalActive)?.at(1) as any) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1.5 }}>
                      Finished:
                    </Typography>
                    <Typography variant="body1">
                      {formatDate(
                        activeSession?.endTime + "Z" === "undefinedZ"
                          ? now
                          : activeSession?.endTime + "Z"
                      )}
                    </Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>
        }
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        {combinedSessions.length > 0 ? (
          <Box
            width="100%"
            mt={3}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginTop: "2.5rem",
            }}
          >
            {sessionsToDisplay.map(([key, value], index) => (
              <Box
                key={key}
                sx={{
                  flex: "0 0 calc(50% - 8rem)",
                  width: "48vw",
                  minWidth: "480px",
                  marginTop: "2.5rem",
                  marginBottom: "2.5rem",
                  "@media (max-width: 1100px)": {
                    flex: "0 0 100%",
                    maxWidth: "95%",
                  },
                }}
              >
                {isPastSession(value) ? (
                  <Box>
                    <Box sx={cardStyle}>
                      <ThumbPlayer
                        vid={
                          (value as pastSessionType)?.privateUrls?.at(0) ||
                          (value as currSessionType)?.resultUrls?.at(0)
                        }
                        img={value.preview}
                      />
                    </Box>
                    {value?.title && (
                      <Box
                        sx={{
                          mx: 2,
                          my: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">{value.title}</Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleClickInfo(index)}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} fontSize={18} />
                          </Button>
                          <Button variant="contained">
                            <FontAwesomeIcon icon={faDownload} fontSize={18} />
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Box sx={cardStyle}>
                      <SessionCard session={value} />
                    </Box>
                    {value?.title && (
                      <Box
                        sx={{
                          mx: 2,
                          my: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">{value.title}</Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleClickInfo(index)}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} fontSize={18} />
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={5}
          >
            <FontAwesomeIcon icon={faKiwiBird} />
            <Typography>Start some video sessions!</Typography>
          </Box>
        )}
        {!allSessionsLoaded && combinedSessions.length > 6 && (
          <div className="my-5">
            <Oval
              visible={true}
              height="45"
              width="45"
              color="#6686af"
              secondaryColor="#6686af"
              ariaLabel="oval-loading"
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

const cardStyle = {
  border: "1px solid #333",
  borderRadius: "12px",
  overflow: "hidden",
  display: "flex",
};

export default ListSessionsPage;
