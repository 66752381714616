import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles/index.css";
import { googleOAuthClientId } from "./env.js";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Home from "./Home";
import ClipPage from "./ClipPage";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import SessionCard from "./components/SessionCard/SessionCard";
import ListSessionsPage from "./ListSessionsPage";
import ListImagesPage from "./ListImagesPage";
import MyProfilePage from "./MyProfilePage";
import TermsAndPrivacy from "./infoPages/TermsAndPrivacy";
import ResultModal from "./components/ClipPage/ResultModal";
import { PaymentModal } from "./components/PaymentModal/PaymentModal";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#6686af", // Your chosen green color
    },
    secondary: {
      main: "#808080",
    },
  },
});

export const DARK_THEME = {
  blue: "#6686af",
  lightpurple: "#AB66FF",
  darkpurple: "#371D57",
  verydarkpurple: "#211135",
  darkgray: "#4e4c4c",
  grayNav: "#272727",
  orange: "#d4491a",
};

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const initContextValue = {
    auth: {
      jwt: null,
      email: null,
      picture: null,
    },
  };
  const [contextValue, setContextValue] = useState(initContextValue);

  return (
    <GoogleOAuthProvider clientId={googleOAuthClientId}>
      <ThemeProvider theme={darkTheme}>
        <Provider store={store}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/clip" element={<ClipPage />} />
              <Route path="/session/:seshId" element={<SessionCard />} />
              <Route path="/listSessions" element={<ListSessionsPage />} />
              <Route path="/listImages" element={<ListImagesPage />} />
              <Route path="/myProfile" element={<MyProfilePage />} />
              <Route path="/terms" element={<TermsAndPrivacy />} />
            </Routes>
            <PaymentModal />
            <ResultModal />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

root.render(<App />);
