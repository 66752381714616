import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { userInfoServerBaseUrl } from "../env";

const AUTH_COOKIE_NAME = "app_auth";

export type authSliceType = {
  jwt: string | null;
  email: string | null;
  picture: string | null;
  subId: string | null;
  bucketName: string | null;
  balance: number | null;
};

const initialState: authSliceType = {
  jwt: null,
  email: null,
  subId: null,
  bucketName: null,
  picture: null,
  balance: 0,
};

const getInitialState = (): authSliceType => {
  const savedAuth = Cookies.get(AUTH_COOKIE_NAME);
  if (savedAuth) {
    try {
      return JSON.parse(savedAuth);
    } catch (e) {
      console.error("Error parsing auth cookie:", e);
    }
  }
  return initialState;
};

export const fetchRemoteBalance = createAsyncThunk(
  "auth/fetchRemoteBalance",
  async (_, { getState }) => {
    console.log("Fetching REMOTE balance");
    const state = getState() as { auth: authSliceType };
    const response = await fetch(`${userInfoServerBaseUrl}/fetchRemoteBalance`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auth: state.auth,
      }),
    });
    let resObj = await response.json();
    console.log(resObj);
    return resObj.balance;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    updateJwt(state, action: PayloadAction<string | null>) {
      state.jwt = action.payload;
    },
    updateEmail(state, action: PayloadAction<string | null>) {
      state.email = action.payload;
    },
    updatePicture(state, action: PayloadAction<string | null>) {
      state.picture = action.payload;
    },
    updateSubId(state, action: PayloadAction<string | null>) {
      state.subId = action.payload;
    },
    updateBucketName(state, action: PayloadAction<string | null>) {
      state.bucketName = action.payload;
    },
    updateBalance(state, action: PayloadAction<number | null>) {
      state.balance = action.payload;
    },
    logout(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemoteBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
      })
      .addCase(fetchRemoteBalance.rejected, (state, action) => {
        console.error("Failed to fetch balance:", action.error);
      });
  },
});

export const {
  updateJwt,
  updateEmail,
  updatePicture,
  updateSubId,
  updateBucketName,
  updateBalance,
  logout,
} = authSlice.actions;

export default authSlice.reducer;

// Middleware to update cookie on auth state changes
export const authCookieMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  if (action.type.startsWith("auth/")) {
    const authState = store.getState().auth;
    if (action.type === "auth/logout") {
      Cookies.remove(AUTH_COOKIE_NAME);
    } else {
      Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(authState), {
        secure: true,
        sameSite: "strict",
      });
    }
  }
  return result;
};
