import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaymentModalState {
  show: boolean;
  paymentAmount: number | null;
}

const initialState: PaymentModalState = {
  show: false,
  paymentAmount: null,
};

const paymentModalSlice = createSlice({
  name: "paymentModal",
  initialState,
  reducers: {
    openPaymentModal(state, action: PayloadAction<number>) {
      state.show = true;
      state.paymentAmount = action.payload;
    },
    closePaymentModal(state) {
      state.show = false;
      state.paymentAmount = null;
    },
    togglePaymentModal(state) {
      state.show = !state.show;
    },
  },
});

export const { openPaymentModal, closePaymentModal, togglePaymentModal } =
  paymentModalSlice.actions;

export default paymentModalSlice.reducer;
