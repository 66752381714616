import React, { useEffect, useState, useContext, useRef } from "react";
import "./styles/Home.css";
import { Box, Card, Paper, Typography } from "@mui/material";

import UploadCard from "./components/ClipPage/UploadCard";
import { useNavigate } from "react-router-dom";
import xflogo from "./xflogo_500.png";
import xfgem from "./xfgem.png";
import MorphingCircle from "./components/HomePage/MorphingCircle";

import { useDispatch, useSelector } from "react-redux";
import { access } from "fs/promises";
import NavBar from "./components/NavBar";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResultModal from "./components/ClipPage/ResultModal";
import Footer from "./components/Footer";
import { DARK_THEME } from ".";
import PaymentAdvertisement from "./components/HomePage/PaymentAdvertisement";

function Home() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    if (videoRef.current) {
      setVideoHeight(videoRef?.current?.clientHeight || 0);
    }
  }, []);

  const signinInProgressRef = useRef(false);

  const [selectedAlignment, setSelectedAlignment] = useState("youtube");

  const handleAlignmentChange = (newAlignment) => {
    setSelectedAlignment(newAlignment);
  };

  const navToClip = () => {
    if (!signinInProgressRef.current) {
      navigate("/clip");
      return;
    }

    const checkAndNavigate = () => {
      if (!signinInProgressRef.current) {
        navigate("/clip");
        clearInterval(intervalId); // Stop interval when done
      }
    };

    const intervalId = setInterval(checkAndNavigate, 100);
  };

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setScrolledDown(true);
      }

      setLastScrollTop(currentScrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const videoSource =
    "https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/startrektktktk.mp4?alt=media&token=20c13921-c070-46d0-a2d1-edbe96ad0019";

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "relative",
          top: 0,
          width: "100%",
          overflow: "hidden",
          zIndex: 888,
        }}
      >
        <NavBar signinInProgressRef={signinInProgressRef} />
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "130vh",
          overflow: "hidden",
        }}
      >
        <video
          autoPlay
          loop
          muted
          ref={videoRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "auto",
            zIndex: "-1",
          }}
        >
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: "1",
          top: "0",
          width: "100%",
          margin: "0 auto",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <div className="flex flex-col items-center" style={{ marginTop: "15vh" }}>
          <img
            src={xflogo}
            alt="clip fake logo"
            style={{
              maxWidth: "20vw",
              imageRendering: "crisp-edges",
              filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
            }}
          />
          <Typography
            variant="h1"
            className="text-[#6686af]"
            style={{
              marginTop: 16,
              filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
              fontWeight: 600,
              fontSize: 45,
            }}
          >
            ClipFake
          </Typography>
        </div>
        <div
          className={`flex flex-col items-center ${
            selectedAlignment === "youtube" ? "mb-[10vh] lg:mb-[25vh]" : "mb-[10vh]"
          }`}
          style={{
            marginTop: "16vh",
            filter: "drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.5))",
          }}
        >
          <UploadCard
            ind={0}
            callback={navToClip}
            handleAlignmentChange={handleAlignmentChange}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            mb: 10,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { lg: "65%", xs: "100%" },
              background: "radial-gradient(circle at top center, #1e1e1e, #121212 60%)",
              borderRadius: 3,
            }}
          >
            <Typography variant="h5" sx={{ mt: 3, mb: 5 }}>
              DeepFake Directly from YouTube
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: { lg: "60%", xs: "90%" },
                  maxWidth: "800px",
                }}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/ClippingAction_v2.jpg?alt=media&token=db46d606-2547-45dc-9dae-d5416e5ebf4f"
                  alt="clipping action"
                />
                <Box sx={{ mt: 1 }}>Clip only the desired portion of the video</Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 3,
                  mt: 5,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: { lg: "20%", xs: "30%", borderRadius: 5 },
                    maxWidth: "800px",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/clipfake.appspot.com/o/Screenshot%20from%202024-08-28%2020-14-38.png?alt=media&token=b7f41ed6-03f6-45d8-8c6b-13fd48b72855"
                  alt="example face"
                />
                <Box sx={{ mt: 1 }}>Overlay a selected face onto the clip</Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                mt: 16,
                mb: 2,
              }}
            >
              <Box component="img" src={xfgem} sx={{ height: 25, width: 25, mr: 2 }} />
              <Typography variant="h5">Pay-as-you-go pricing</Typography>
              <Box component="img" src={xfgem} sx={{ height: 25, width: 25, ml: 2 }} />
            </Box>
            <Typography variant="h6">
              No more bogus subscriptions. No more "free" trials that charge later -
            </Typography>
            <Typography variant="h6">
              Get started deepfaking YouTube videos for cheap, right now!
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <PaymentAdvertisement amount={70} message="to get started deepfaking" />
              <PaymentAdvertisement amount={599} message="for deepfaking enjoyers" />
              <PaymentAdvertisement
                amount={1299}
                message="for enthusiasts and content creators"
              />
            </Box>
          </Box>
        </Box>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
