export const serviceAccJson = {
  type: "service_account",
  project_id: "clipfake",
  private_key_id: "ccf58c10380bbdf3713c1039a2a5fa60caa73709",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2tx62tHA6V2co\n2/87URzcsvxCSJ4Oxiw7pa6lbBbSNeZaZyFcPCrRUJG5wbfX5N+6+0xZ3wen19Fb\nVacQ8AOHT7xVrpGOHRa9PhMpoJV4ZNkrFc8uHGwF6I3/MxKOuqLAPfcvVC9rZCuZ\na1DM618RvuG50ancd4BvviClPNrdsXvrPbghka6DgMaVAox8nL9Y3saSO2LleUnF\nzWfrp+FBmqwoEo3EsjWNN3WFwviSQ0HMp/EBDyBd9ztJ+I6IUEHLwIY/KEnmB5nX\nCwh4iLdrd3gvV+WfV8Zxcf9j6XYLJYbfr8HOxejR8esngE2391StRs84BuZc62ky\n876YWKW1AgMBAAECggEANKjCKVYHokjeamA9RxMxhknvegsDwq5KwUYW94YXba4r\nHZLrgnG0xW67ERZLreVAtdsHPj+RLUZ+CBy/cZo66hyI4wjU2mh3zUK+sxic+33V\nOR2oAFogp/NGOW62wnv3vAAf5XwVzpWoGydaujR7ydmBpNRQaEHoukvJjNXlHk80\nAnJoHINbv2ctNPYPTkCi3+ZbHacHGbSMadwRNh1ZiqtbnalAn4gyyzRbRf3ucxwX\nJuLd//YGT7wlaVARcHo1BEg54QUW9Y4NhhGdhbjo1g2YtWiA9HPa1jr/f7pHIg0F\nhRYCDGb70cfYdwbkhAmVlOAeyfVHNfmLQwGTkGeGYQKBgQDbTP4hkjukCIP1qcDX\njluk1bUDFD5Twf0k4dWOH6M8WV/F1ki121Zm++a5grnjykwETvlq6oVgTP9FlAV7\nO9fYdl+TbMOT3YFTmT3Jw4jdfd/3kOqjQbu/evPvrrHuIrmNvrHdSBmGRspollhX\nCL4C8ul7PLjssOwRkZ/8M1aJGwKBgQDVSsehol9cvYXqc7laqNxbq7LijoyN4bpM\nmNITc49UPGmn2cznFLgMU2oE2BTW9nJ+LrfkDq061n4EKothXCShTZv1aEF41/0t\nygL6FKecI0kEUaSUfMWQo5LpEgH7iuZlGGDKlDaFHzfaMVBcBojiSMMziIgvhE1k\n571/JFfJbwKBgCzmRJnHAOMCw1HG4a7AfM7w2lC5ElG/w/6LJlgk7hzrB4rP5q8l\nKzmGlaShuUGEJ7AyIyravVgMK6+pML4ccILnKYZbnGdn0JS3KUln+Vuv6+0aBJBM\nYw4/NDZ8g/2Hqon04yG1I1S0pbhoH8oiBhVAesE6m3oKdyrZr/HI6QzFAoGABdha\nyC8u9derx9UMC1P9VfXKnTLS30CEXn0BpM7R6rkNHDB30Q1cTdorWZ6iU5HFaXfW\nSkWssaQXJ/4Se8SOrGz4DS2iPbGNmqeilT/jp/+/jILAQ+F3eJNC/2gi7cXMspCM\ndoDBTbyhRzV6wkrbjXf5AXyIXuT9Sfqf0tAoJ7kCgYEApq8GJZ9UCS04W8/q3Oi4\nxbEYmFSmAr6+4j5cp1uoeUadQzdhF3nKWY69kLYhXbaHNWJJ1ZA253KpA+hGePE5\nk2wFAGOgAdkHoKMYEBIpMgcCx7sMJ0vvcpYETDFz6lXfokpriwwdTaPyeueQIl4i\nHe+tZV0s7taYSYUBfqMWqMw=\n-----END PRIVATE KEY-----\n",
  client_email: "userinfo-serviceacc@clipfake.iam.gserviceaccount.com",
  client_id: "105117168766251098678",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/userinfo-serviceacc%40clipfake.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
};

export const googleOAuthClientId =
  "1051756212324-ruinjo71rs9utlkciei99mlgaltdlh8o.apps.googleusercontent.com";

export const userInfoServerBaseUrl = "https://infoandseshfind-xjbws6a7ca-uc.a.run.app";

export const sessionServerBaseUrl = "https://gpu-finder-public-cu3tv4lx2a-uc.a.run.app";
export const sessionServerNoHttp = "gpu-finder-public-cu3tv4lx2a-uc.a.run.app";
export const testB = "test";

export const API_KEY =
  "pk_live_51Psvs1Fw9TjERAIgEvgbVJn325Ig6Lo7rcR38H0RZUvCO4aeVrDGYdv6Q6uSTK5TQcD5oW33ACWD73prXMnCTwLc00ZbWvzYK5";
export const API_KEY_TEST =
  "pk_test_51Psvs1Fw9TjERAIgL2jhDYJrQQD6Hilq4pBD7DpyUuVcgrr5PUtrtHrdMB83n5KWyrOYC4b2mAnVEvF4nXVTKxCm00KQodZBvg";
