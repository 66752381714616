import { Box, Typography } from "@mui/material";
import React from "react";
import { DARK_THEME } from "../..";
import xfgem from "../../xfgem.png";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { openPaymentModal } from "../../redux/paymentModalSlice";

const PaymentAdvertisement = ({ amount, message }) => {
  const dispatch = useDispatch();

  return (
    <Box
      onClick={() => dispatch(openPaymentModal(amount))}
      sx={{
        borderColor: DARK_THEME.lightpurple,
        borderWidth: 1,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        width: "200px",
        alignItems: "center",
        justifyContent: "space-between",
        my: 3,
        mx: 2,
        p: 3,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: DARK_THEME.verydarkpurple,
        },
      }}
    >
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>${(amount / 100).toFixed(2)}</Typography>
        <Typography>for</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box component="img" src={xfgem} sx={{ height: 20, width: 20, mr: 0.5 }} />
          <Typography sx={{ color: DARK_THEME.lightpurple, fontWeight: 600 }}>
            {amount}
          </Typography>
        </Box>
      </Box>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default PaymentAdvertisement;
